import apiProvider from "@/utils/api";
import {toUrl} from "@/utils/helpers"
import ComplementsCoutsSupp from "@/models/ComplementsCoutsSuppAdministration";

const rootPath = 'ComplementsCoutsSuppAdministration';


class ComplementsCoutsSuppAdministrationService {

    public getComplementsCoutsSuppAdministrationTableUrl(lang: string,typeTravaux:string = ""): string {
        let queryParams = {
            lang
        };
        if(typeTravaux.length){
            queryParams['typeTravaux']=typeTravaux;
        }
        return `${apiProvider.getUrl()}${rootPath}${toUrl(queryParams)}`;
    }

    public async getComplementsCoutsSuppAdministration(lang: string,typeTravaux:string = ""){
        return apiProvider.get(this.getComplementsCoutsSuppAdministrationTableUrl(lang,typeTravaux));
    }
    public async save(complementsCoutsSupps: Array<ComplementsCoutsSupp>): Promise<any> {
        return apiProvider.post(`${apiProvider.getUrl()}${rootPath}`, complementsCoutsSupps);
    }

}

export default new ComplementsCoutsSuppAdministrationService();