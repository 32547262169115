import { Guid } from "guid-typescript";

export default class ComplementaryData{
    public id: string;
    public fournitures!: number;
    public fournituresDescription: string;
    public locations!: number;
    public locationsDescription: string;
    public sousTraitance!: number;
    public sousTraitanceDescription: string;
    public etudes!: number;
    public etudesDescription: string;


    constructor() {
        this.id = Guid.createEmpty().toString();
        this.fournituresDescription = "";
        this.locationsDescription = "";
        this.sousTraitanceDescription = "";
        this.etudesDescription = "";


    }
  }
  