import { Guid } from "guid-typescript";

export default class HeightSupplement{
    public id: string;
    public quantity: number;
    public coefficientOptionId: string;
  

    constructor() {
        this.id = Guid.createEmpty().toString();
        this.quantity = 0
        this.coefficientOptionId = ""
    }
  }
  