import { Guid } from 'guid-typescript';
import apiProvider from "@/utils/api";
import axios, {AxiosPromise, AxiosResponse} from "axios";
import {toUrl} from "@/utils/helpers"
import CoefficientConfig from "@/models/CoefficientConfig"
import GroupedType5CoefficientSelectedValueByValue from "@/models/GroupedType5CoefficientSelectedValueByValue";
const rootPath = 'coefficientSelectedValues';


class CoefficientSelectedValueService {
  
    public  getCoefficientType5TableUrl(coefficientId:string,bopModelId:string,lang:string): string {
        return `${apiProvider.getUrl()}${rootPath}/T5-administration${toUrl({coefficientId,bopModelId,lang})}`;
    }
    public  getCoefficientType5(coefficientId:string,bopModelId:string,lang:string):Promise<AxiosResponse<Array<GroupedType5CoefficientSelectedValueByValue>>> {
        return apiProvider.get(this.getCoefficientType5TableUrl(coefficientId,bopModelId,lang));
    }
    public  GetCoefficientType5ValuesBysite(bopModelId:string,siteId:string): Promise<any> {
        return apiProvider.get(`${apiProvider.getUrl()}${rootPath}/T5${toUrl({bopModelId,siteId})}`);
    }
    
 
    public async saveCoefficientType5Values(type5SaveRequest:type5SaveRequest): Promise<any> {
        return apiProvider.post(`${apiProvider.getUrl()}${rootPath}/T5-administration`,type5SaveRequest);
    }
    public deleteType5CoefficientBySiteIds(coefficientId:string,bopModelId:string,value:number,languageCode:string,siteIds:Array<string>|undefined = undefined){
        const params = {coefficientId,bopModelId,value,languageCode};
        if(siteIds){
            params['siteIds']=siteIds;
        }
        return apiProvider.delete(`${apiProvider.getUrl()}${rootPath}/T5-administration${toUrl(params)}`)
    }
    
}
export type type5SaveRequest = {
    coefficientSelectedValuesToDelete: Array<string>,
    coefficientSelectedValuesToSave: Array<GroupedType5CoefficientSelectedValueByValue>
    languageCode:string
}
export default new CoefficientSelectedValueService();